import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const EditEvent = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const eventId = params.get("id");// Get the eventId from the URL
  const [formFields, setFormFields] = useState({
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    gifts: [],
    image: null,
    formImage: null,
    leaderboardImage: null,
    status:0,
    color1:"",
    color2:"",
    color3:"",
    statusAmount:0,
    gifterStatus:0,
    gifterAmount:0,
  });
  const [giftsOptions, setGiftsOptions] = useState([]);
  const navigate = useNavigate();

  // Fetch the event details and populate the form
  useEffect(() => {
    const fetchEventDetails = async () => {
      await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/banner/getEventById?eventId=${eventId}`).then((response)=>{
        if (response.data.success == 1) {
          toast.success(response.data.message);
          const event = response.data.details;
          setFormFields({
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            startTime: event.startTime,
            endTime: event.endTime,
            gifts: event.gifts.map((gift) => gift.id),
            image: event.image,
            formImage: event.formImage,
            leaderboardImage: event.leaderboardImage,
            color1:event.color1,
            color2:event.color2,
            color3:event.color3,
            status:event.status,
            statusAmount:event.statusAmount,
            gifterStatus:event.gifterStatus,
            gifterAmount:event.gifterAmount,
          });
        } else {
          toast.error(response.data.message);
        }
      }).catch((e)=>console.log(e));
      // try {
      //   const response = await axios.get(
      //     `https://mastishortss.com/v5/admin/banner/getEventById?eventId=${eventId}`
      //   );
      //   if (response.data.success == 1) {
      //     const event = response.data.details;
      //     setFormFields({
      //       name: event.name,
      //       startDate: event.startDate,
      //       endDate: event.endDate,
      //       startTime: event.startTime,
      //       endTime: event.endTime,
      //       gifts: event.gifts.map((gift) => gift.id),
      //       image: event.image,
      //       formImage: event.formImage,
      //       leaderboardImage: event.leaderboardImage,
      //     });
      //   }
      // } catch (error) {
      //   console.error("Error fetching event details:", error);
      //   toast.error("Failed to fetch event details");
      // }
    };

    const fetchGifts = async () => {
        await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/livegifts/getlivegifts`).then((response)=>{
          if (response.data.success === 1) {
            toast.success(response.data.message);
            setGiftsOptions(response.data.data);
          }else{
            toast.error(response.data.message);
          }
        }).catch((e)=>toast.error(e.message));
    };

    fetchEventDetails();
    fetchGifts();
  }, [eventId]);

  const handleInputChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const handleGiftSelection = (giftId) => {
    const selectedGifts = [...formFields.gifts];
    if (selectedGifts.includes(giftId)) {
      const updatedGifts = selectedGifts.filter((id) => id !== giftId);
      setFormFields({ ...formFields, gifts: updatedGifts });
    } else {
      if (selectedGifts.length < 3) {
        setFormFields({ ...formFields, gifts: [...selectedGifts, giftId] });
      } else {
        toast.error("You can select a maximum of 3 gifts.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("gifts", JSON.stringify(formFields.gifts)); // Sending gift IDs as an array
    try {
      event.target.reset();

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/banner/editEvents?eventId=${eventId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success === 1) {
        toast.success("Event successfully updated");
        navigate("/admin/GetEvents");
      } else {
        toast.error("Failed to update event");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to update event");
    }
  };

  return (
    <>
      <section className="content-header">
        <ol className="breadcrumb">
          <li>
            <i className="fa fa-dashboard"></i> Home
          </li>
          <li className="active">Edit Event</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Event</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Image*</label>
                    <input type="file" className="form-control" name="image" />
                    {formFields.image && (
                      <img src={formFields.image} alt="Event" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Register Form Image*</label>
                    <input type="file" className="form-control" name="formImage" />
                    {formFields.formImage && (
                      <img src={formFields.formImage} alt="Form" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Leaderboard Image*</label>
                    <input type="file" className="form-control" name="leaderboardImage" />
                    {formFields.leaderboardImage && (
                      <img src={formFields.leaderboardImage} alt="Leaderboard" width="100" />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formFields.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Color1*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="color1"
                      value={formFields.color1}
                      onChange={handleInputChange}
                      // required
                    />
                  </div>
                  <div className="form-group">
                    <label>Color2*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="color2"
                      value={formFields.color2}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Color3*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="color3"
                      value={formFields.color3}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={formFields.startDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date*</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={formFields.endDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Time*</label>
                    <input
                      type="time"
                      className="form-control"
                      name="startTime"
                      value={formFields.startTime}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Time*</label>
                    <input
                      type="time"
                      className="form-control"
                      name="endTime"
                      value={formFields.endTime}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div>
                        <label style={{ marginRight: "1rem" }}>Event Status</label>
                        <div>
                          <label htmlFor="status-free" style={{marginRight:"1rem"}}>Free: </label>
                          <input type="radio" id="status-free" name="status" value={0}  onChange={handleInputChange}  style={{width:"2rem"}} checked={formFields.status == 0}/>
                          <label htmlFor="status-paid" style={{marginRight:"1rem"}}>paid: </label>
                          <input type="radio" id="status-paid" name="status" value={1}  onChange={handleInputChange}  style={{width:"2rem"}} checked={formFields.status == 1}/>
                        </div>
                  </div>
                  {formFields.status == 1 ? (
                        <div className="form-group">
                          <label>Enter Amount :</label>
                          <input type="number" name="statusAmount" onChange={handleInputChange} className="form-control" value={formFields.statusAmount} required/>
                        </div>
                  ):null}
                  <div>
                        <label style={{ marginRight: "1rem" }}>Gifter Status</label>
                        <div>
                          <label htmlFor="status-free" style={{marginRight:"1rem"}}>NO </label>
                          <input type="radio" id="gifterStatus-free" name="gifterStatus" value={0}  onChange={handleInputChange}  style={{width:"2rem"}} checked={formFields.gifterStatus == 0}/>
                          <label htmlFor="status-paid" style={{marginRight:"1rem"}}>YES: </label>
                          <input type="radio" id="gifterStatus-paid" name="gifterStatus" value={1}  onChange={handleInputChange}  style={{width:"2rem"}} checked={formFields.gifterStatus == 1}/>
                        </div>
                  </div>
                  {formFields.gifterStatus == 1 ? (
                        <div className="form-group">
                          <label>Enter Amount for Gifter :</label>
                          <input type="number" name="gifterAmount" onChange={handleInputChange} className="form-control" value={formFields.gifterAmount} required/>
                        </div>
                  ):null}
                  <div className="form-group">
                    <label>Gifts*</label>
                    <div className="mb-3">
                      {giftsOptions.map((gift) => (
                        <div key={gift._id} className="form-check position-relative">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={gift._id}
                            checked={formFields.gifts.includes(gift._id)}
                            onChange={() => handleGiftSelection(gift._id)}
                          />
                          <label className="form-check-label">
                            <img
                              src={`${process.env.REACT_APP_BASEURL1}${gift.giftimage}`}
                              alt={gift.title}
                              width="30"
                              className="me-2"
                            />
                            <span>{gift.title}</span> - {gift.coin} coins
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success pull-right"
                    >
                      Update Event
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditEvent;
